import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useRouter } from 'next/router';
import { getToken, getLanguage } from '@/utils/cookies';
import { screens } from '@/constants';
import DashboardPage from './dashboard';
import LoginPage from './login';

const IndexPage = ({ token, accountMe }) => {
  const router = useRouter();

  useEffect(() => {
    router.replace('/', token ? screens.DASHBOARD : screens.LOGIN, { shallow: true });
  }, [token]);

  return token ? <DashboardPage accountMe={accountMe} /> : <LoginPage />;
};

IndexPage.getInitialProps = ctx => ({ token: getToken(ctx), initialLanguage: getLanguage(ctx) });

IndexPage.defaultProps = {
  token: '',
  accountMe: {},
};

IndexPage.propTypes = {
  token: PropTypes.string,
  accountMe: PropTypes.shape({
    bitsa: PropTypes.shape({
      level: PropTypes.string,
    }),
  }),
};

export default IndexPage;
